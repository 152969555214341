// En Quiz.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/quiz.scss";
import "../styles/login.scss";
import Navbar from "./navbar";
import QuizGroups from "./quiz-groups";
import QuizForm from "./quiz-form";
import {
  deleteUserResponsesDB,
  getImgUrl,
  getUserDataDB,
  getUserInfoDB,
} from "../lib/db";
import ModalEditProfile from "./modal-edit-profile";

function Quiz() {
  const [user, setUser] = useState();
  const [userLang] = useState("es");
  const [questionsData, setQuestionData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userData, setUserData] = useState();
  const [userStateForm, setUserStateForm] = useState();
  const [userTypeForm, setUserTypeForm] = useState();
  const [currentQuestionsGroup, setCurrentQuestionsGroup] = useState();
  const [currentOptionsGroup, setCurrentOptionsGroup] = useState();
  const [showModalDepartament, setShowModalDepartament] = useState(false); // Inicializado como falso
  const location = useLocation();
  const [userGender, setUserGender] = useState();
  const [userAge, setUserAge] = useState();
  const [userBusiness, setUserBusiness] = useState();
  const [userDepartament, setUserDepartament] = useState();
  const [userProgramasTransversalesOp1, setUserProgramasTransversalesOp1] =
    useState("");
  const [userProgramasTransversalesOp2, setUserProgramasTransversalesOp2] =
    useState("");
  const [userProgramasTransversalesOp3, setUserProgramasTransversalesOp3] =
    useState("");
  const [userProgramasTransversalesOp4, setUserProgramasTransversalesOp4] =
    useState("");
  const [userProgramasTransversalesOp5, setUserProgramasTransversalesOp5] =
    useState("");
  const [userProgramasTransversalesOp6, setUserProgramasTransversalesOp6] =
    useState("");
  const [userProgramasTransversalesOp7, setUserProgramasTransversalesOp7] =
    useState("");

  useEffect(() => {
    if (location.state.user) {
      setUser(location.state.user);
      getUserDepartament(location.state.user.userInfo.id);
    }
    if (location.state.questionsData) {
      getImgsUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getUserDepartament = async (userId) => {
    const data = await getUserInfoDB(userId);
    if (Object.values(data).length > 0) {
      setUserProgramasTransversalesOp1(data.programasTransversales1);
      setUserProgramasTransversalesOp2(data.programasTransversales2);
      setUserProgramasTransversalesOp3(data.programasTransversales3);
      setUserProgramasTransversalesOp4(data.programasTransversales4);
      setUserProgramasTransversalesOp5(data.programasTransversales5);
      setUserProgramasTransversalesOp6(data.programasTransversales6);
      setUserProgramasTransversalesOp7(data.programasTransversales7);
      setUserDepartament(data.departament);
      setUserAge(data.age);
      setUserBusiness(data.business);
      setUserGender(data.gender);
    }
  };

  useEffect(() => {
    if (user) {
      setUserInfo(user.userInfo);
      setUserStateForm(user.userStateForm);
    }
  }, [user]);

  useEffect(() => {
    if (userStateForm === "new") {
      deleteUserResponses();
      setUserData({});
    } else {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStateForm, userInfo]);

  const deleteUserResponses = async () => {
    await deleteUserResponsesDB(user.userInfo.id, user.userInfo.companyId);
  };

  const getUserData = async () => {
    if (userInfo) {
      setUserData(await getUserDataDB(userInfo.id, userInfo.companyId));
    }
  };

  const getImgsUrl = async () => {
    Object.values(location.state.questionsData.questions).map(
      async (question) => {
        if (question.image) {
          question.image = await getImgUrl(question.image);
        }
      }
    );
    setQuestionData(location.state.questionsData);
  };

  return (
    <div className="quiz-div">
      <Navbar
        userLang={userLang}
        bg="-white"
        userName={user?.userInfo.name || "Usuario"}
        setShowModalDepartament={setShowModalDepartament} // Pasar la función para actualizar el estado showModalDepartament
        isQuizPage={true}
      />
      {showModalDepartament && (
        <ModalEditProfile
          userInfo={userInfo}
          setShowModalDepartament={setShowModalDepartament}
          userGenderProp={userGender}
          userAgeProp={userAge}
          userBusinessProp={userBusiness}
          userDepartamentProp={userDepartament}
          setUserGenderProp={setUserGender}
          setUserAgeProp={setUserAge}
          setUserBusinessProp={setUserBusiness}
          setUserDepartamentProp={setUserDepartament}
          userProgramasTransversalesOp1Prop={userProgramasTransversalesOp1}
          userProgramasTransversalesOp2Prop={userProgramasTransversalesOp2}
          userProgramasTransversalesOp3Prop={userProgramasTransversalesOp3}
          userProgramasTransversalesOp4Prop={userProgramasTransversalesOp4}
          userProgramasTransversalesOp5Prop={userProgramasTransversalesOp5}
          userProgramasTransversalesOp6Prop={userProgramasTransversalesOp6}
          userProgramasTransversalesOp7Prop={userProgramasTransversalesOp7}
          setUserProgramasTransversalesOp1Prop={
            setUserProgramasTransversalesOp1
          }
          setUserProgramasTransversalesOp2Prop={
            setUserProgramasTransversalesOp2
          }
          setUserProgramasTransversalesOp3Prop={
            setUserProgramasTransversalesOp3
          }
          setUserProgramasTransversalesOp4Prop={
            setUserProgramasTransversalesOp4
          }
          setUserProgramasTransversalesOp5Prop={
            setUserProgramasTransversalesOp5
          }
          setUserProgramasTransversalesOp6Prop={
            setUserProgramasTransversalesOp6
          }
          setUserProgramasTransversalesOp7Prop={
            setUserProgramasTransversalesOp7
          }
          userLang={userLang}
        />
      )}
      <div className="quiz-body">
        <div className="quiz-groups-div">
          <QuizGroups
            userInfo={userInfo}
            userDataProp={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            setCurrentQuestionsGroup={setCurrentQuestionsGroup}
            setCurrentOptionsGroup={setCurrentOptionsGroup}
            setUserTypeForm={setUserTypeForm}
            userLang={userLang}
          />
        </div>
        <div className="quiz-questions-div">
          <QuizForm
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            userLang={userLang}
          />
        </div>
      </div>
    </div>
  );
}

export default Quiz;
