import React, { useEffect, useState } from "react";
import { updateUserProfileDB } from "../lib/db";
import "../styles/modal-select-departament.scss";
import LoadingSpinner from "./loadingSpinner";

const ModalEditProfile = ({
  userInfo,
  setShowModalDepartament,
  userGenderProp,
  userAgeProp,
  userBusinessProp,
  userDepartamentProp,
  userProgramasTransversalesOp1Prop,
  userProgramasTransversalesOp2Prop,
  userProgramasTransversalesOp3Prop,
  userProgramasTransversalesOp4Prop,
  userProgramasTransversalesOp5Prop,
  userProgramasTransversalesOp6Prop,
  userProgramasTransversalesOp7Prop,
  setUserGenderProp,
  setUserAgeProp,
  setUserBusinessProp,
  setUserDepartamentProp,
  setUserProgramasTransversalesOp1Prop,
  setUserProgramasTransversalesOp2Prop,
  setUserProgramasTransversalesOp3Prop,
  setUserProgramasTransversalesOp4Prop,
  setUserProgramasTransversalesOp5Prop,
  setUserProgramasTransversalesOp6Prop,
  setUserProgramasTransversalesOp7Prop,
  userLang,
}) => {
  const [userGender, setUserGender] = useState(userGenderProp);
  const [userAge, setUserAge] = useState(userAgeProp);
  const [userBusiness, setUserBusiness] = useState(userBusinessProp);
  const [userDepartament, setUserDepartament] = useState(userDepartamentProp);
  const [userProgramasTransversalesOp1, setUserProgramasTransversalesOp1] =
    useState(userProgramasTransversalesOp1Prop);
  const [userProgramasTransversalesOp2, setUserProgramasTransversalesOp2] =
    useState(userProgramasTransversalesOp2Prop);
  const [userProgramasTransversalesOp3, setUserProgramasTransversalesOp3] =
    useState(userProgramasTransversalesOp3Prop);
  const [userProgramasTransversalesOp4, setUserProgramasTransversalesOp4] =
    useState(userProgramasTransversalesOp4Prop);
  const [userProgramasTransversalesOp5, setUserProgramasTransversalesOp5] =
    useState(userProgramasTransversalesOp5Prop);
  const [userProgramasTransversalesOp6, setUserProgramasTransversalesOp6] =
    useState(userProgramasTransversalesOp6Prop);
  const [userProgramasTransversalesOp7, setUserProgramasTransversalesOp7] =
    useState(userProgramasTransversalesOp7Prop);

  const [isLoading, setIsLoading] = useState(false); // false aqui y no en home

  useEffect(() => {
    setUserGender(userGenderProp);
  }, []);

  useEffect(() => {
    if (userInfo?.departament) {
      setUserDepartament(userInfo?.departament);
    }
  }, [userInfo]);

  const getUserProgramasTransversalesOp1 = (event) => {
    setUserProgramasTransversalesOp1(event.target.value);
  };

  const getUserProgramasTransversalesOp2 = (event) => {
    setUserProgramasTransversalesOp2(event.target.value);
  };

  const getUserProgramasTransversalesOp3 = (event) => {
    setUserProgramasTransversalesOp3(event.target.value);
  };

  const getUserProgramasTransversalesOp4 = (event) => {
    setUserProgramasTransversalesOp4(event.target.value);
  };

  const getUserProgramasTransversalesOp5 = (event) => {
    setUserProgramasTransversalesOp5(event.target.value);
  };

  const getUserProgramasTransversalesOp6 = (event) => {
    setUserProgramasTransversalesOp6(event.target.value);
  };

  const getUserProgramasTransversalesOp7 = (event) => {
    setUserProgramasTransversalesOp7(event.target.value);
  };

  const getUserDepartament = (event) => {
    setUserDepartament(event.target.value);
  };

  const getUserGender = (event) => {
    setUserGender(event.target.value);
  };

  const getUserAge = (event) => {
    setUserAge(event.target.value);
  };

  const getUserBusiness = (event) => {
    setUserBusiness(event.target.value);
  };

  const updateUserProfile = async () => {
    if (userGender && userAge && userBusiness && userDepartament) {
      setIsLoading(true);
      await updateUserProfileDB(
        userInfo,
        userGender,
        userAge,
        userBusiness,
        userDepartament,
        userProgramasTransversalesOp1,
        userProgramasTransversalesOp2,
        userProgramasTransversalesOp3,
        userProgramasTransversalesOp4,
        userProgramasTransversalesOp5,
        userProgramasTransversalesOp6,
        userProgramasTransversalesOp7
      );
      if (setUserGenderProp) {
        setUserGenderProp(userGender);
      }
      if (setUserAgeProp) {
        setUserAgeProp(userAge);
      }
      if (setUserBusinessProp) {
        setUserBusinessProp(userBusiness);
      }
      if (setUserDepartamentProp) {
        setUserDepartamentProp(userDepartament);
      }
      if (setUserProgramasTransversalesOp1Prop) {
        setUserProgramasTransversalesOp1Prop(userProgramasTransversalesOp1);
      }
      if (setUserProgramasTransversalesOp2Prop) {
        setUserProgramasTransversalesOp2Prop(userProgramasTransversalesOp2);
      }
      if (setUserProgramasTransversalesOp3Prop) {
        setUserProgramasTransversalesOp3Prop(userProgramasTransversalesOp3);
      }
      if (setUserProgramasTransversalesOp4Prop) {
        setUserProgramasTransversalesOp4Prop(userProgramasTransversalesOp4);
      }
      if (setUserProgramasTransversalesOp5Prop) {
        setUserProgramasTransversalesOp5Prop(userProgramasTransversalesOp5);
      }
      if (setUserProgramasTransversalesOp6Prop) {
        setUserProgramasTransversalesOp6Prop(userProgramasTransversalesOp6);
      }
      if (setUserProgramasTransversalesOp7Prop) {
        setUserProgramasTransversalesOp7Prop(userProgramasTransversalesOp7);
      }

      setShowModalDepartament(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-select-main">
        <div className="modal-select-div">
          <select
            value={userGender}
            onChange={getUserGender}
            className="modal-select"
            title={
              userLang === "es" ? "Seleccione Género *" : "Select Gender *"
            }
          >
            <option value="-" disabled>
              {userLang === "es" ? "Seleccione Género *" : "Select Gender *"}
            </option>
            <option value="MALE">
              {userLang === "es" ? "MASCULINO" : "MALE"}
            </option>
            <option value="FEMALE">
              {userLang === "es" ? "FEMENINO" : "FEMALE"}
            </option>
            <option value="UNDEFINED">
              {userLang === "es" ? "SIN DEFINIR" : "UNDEFINED"}
            </option>
          </select>

          <select
            value={userAge}
            onChange={getUserAge}
            className="modal-select"
            title={userLang === "es" ? "Seleccione Edad *" : "Select Age *"}
          >
            <option disabled value="-">
              {userLang === "es" ? "Seleccione Edad *" : "Select Age *"}
            </option>
            <option value="FROM 20 TO 30">
              {userLang === "en" ? "FROM 20 TO 30" : "DE 20 A 30"}
            </option>
            <option value="FROM 31 TO 40">
              {userLang === "en" ? "FROM 31 TO 40" : "DE 31 A 40"}
            </option>
            <option value="FROM 41 TO 50">
              {userLang === "en" ? "FROM 41 TO 50" : "DE 41 A 50"}
            </option>
            <option value="FROM 51 TO 60">
              {userLang === "en" ? "FROM 51 TO 60" : "DE 50 A 61"}
            </option>
            <option value="MORE THAN 61">
              {userLang === "en" ? "MORE THAN 61" : "MÁS DE 61"}
            </option>
          </select>

          <select
            value={userBusiness}
            onChange={getUserBusiness}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Localización *"
                : "Select Location *"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Localización *"
                : "Select Location *"}
            </option>
            <option value="OTROS CENTROS DEL DEPARTAMENTO CLÍNICO-MALVARROSA">
              OTROS CENTROS DEL DEPARTAMENTO CLÍNICO-MALVARROSA
            </option>
            <option value="UNIVERSITAT DE VALÈNCIA">
              UNIVERSITAT DE VALÈNCIA
            </option>
            <option value="HOSPITAL CLÍNICO UNIVERSITARIO DE VALENCIA">
              HOSPITAL CLÍNICO UNIVERSITARIO DE VALENCIA
            </option>
            <option value="FUNDACIÓN CARLOS SIMÓN">
              FUNDACIÓN CARLOS SIMÓN
            </option>
            <option value="IIS INCLIVA">IIS INCLIVA</option>
            <option value="CIBER">CIBER</option>
          </select>

          <select
            id="select-user-departament"
            value={userDepartament}
            onChange={getUserDepartament}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Grupo de Investigación *"
                : "Select Research Group *"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Grupo de Investigación *"
                : "Select Research Group *"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - ELECTROFISIOLOGÍA CARDIACA EXPERIMENTAL">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - ELECTROFISIOLOGÍA CARDIACA EXPERIMENTAL"
                : "CARDIOVASCULAR AREA - EXPERIMENTAL CARDIAC ELECTROPHYSIOLOGY"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - RIESGO CARDIOMETABÓLICO Y DIABETES">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - RIESGO CARDIOMETABÓLICO Y DIABETES"
                : "COMERCIAL"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - CÉLULAS ENDOTELIALES (LINCE)">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - CÉLULAS ENDOTELIALES (LINCE)"
                : "ESTUDIOS"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - CARDIOLOGÍA CLÍNICA">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - CARDIOLOGÍA CLÍNICA"
                : "OPERACIONES"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - PREVENCIÓN DEL RIESGO CARDIOVASCULAR EN NIÑOS Y ADOLESCENTES">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - PREVENCIÓN DEL RIESGO CARDIOVASCULAR EN NIÑOS Y ADOLESCENTES"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - INSUFICIENCIA CARDIACA">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - INSUFICIENCIA CARDIACA"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - ESTUDIO DE RIESGO CARDIOMETABÓLICO Y RENAL">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - ESTUDIO DE RIESGO CARDIOMETABÓLICO Y RENAL"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - INVESTIGACIÓN CARDIOMETABÓLICA EN ATENCIÓN PRIMARIA">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - INVESTIGACIÓN CARDIOMETABÓLICA EN ATENCIÓN PRIMARIA"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - FUNCIÓN VASCULAR">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - FUNCIÓN VASCULAR"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - NUTRICIÓN PEDIÁTRICA">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - NUTRICIÓN PEDIÁTRICA"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - INVESTIGACIÓN TRASLACIONAL EN CARDIOPATÍA ISQUÉMICA">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - INVESTIGACIÓN TRASLACIONAL EN CARDIOPATÍA ISQUÉMICA"
                : "x"}
            </option>
            <option value="ÁREA CARDIOVASCULAR - UNIDAD DE GENÓMICA Y DIABETES">
              {userLang === "es"
                ? "ÁREA CARDIOVASCULAR - UNIDAD DE GENÓMICA Y DIABETES"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - GENÓMICA TRASLACIONAL HUMANA">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - GENÓMICA TRASLACIONAL HUMANA"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - DETERIORO NEUROLÓGICO">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - DETERIORO NEUROLÓGICO"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ANESTESIOLOGÍA Y REANIMACIÓN">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ANESTESIOLOGÍA Y REANIMACIÓN"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - AUTONOMÍA PERSONAL, DEPENDENCIA Y TRASTORNOS MENTALES">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - AUTONOMÍA PERSONAL, DEPENDENCIA Y TRASTORNOS MENTALES"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - BIOQUÍMICA TISULAR">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - BIOQUÍMICA TISULAR"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - CIRUGÍA GENERAL Y DIGESTIVA">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - CIRUGÍA GENERAL Y DIGESTIVA"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - EJERCICIO, NUTRICIÓN Y ESTILO DE VIDA SALUDABLE">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - EJERCICIO, NUTRICIÓN Y ESTILO DE VIDA SALUDABLE"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES METABÓLICAS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES METABÓLICAS"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES RARAS RESPIRATORIAS (ERR)">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES RARAS RESPIRATORIAS (ERR)"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES RESPIRATORIAS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENFERMEDADES RESPIRATORIAS"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENVEJECIMIENTO SALUDABLE (MINIAGING)">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENVEJECIMIENTO SALUDABLE (MINIAGING)"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENVEJECIMIENTO Y EJERCICIO FÍSICO">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - ENVEJECIMIENTO Y EJERCICIO FÍSICO"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - EPIGENÓMICA Y EPIGENÉTICA TRASLACIONAL">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - EPIGENÓMICA Y EPIGENÉTICA TRASLACIONAL"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - FISIOPATOLOGÍA CELULAR Y ORGÁNICA DEL ESTRÉS OXIDATIVO">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - FISIOPATOLOGÍA CELULAR Y ORGÁNICA DEL ESTRÉS OXIDATIVO"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - INFLAMACIÓN">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - INFLAMACIÓN"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - NEUROBIOLOGÍA Y FISIOPATOLOGÍA MOLECULAR EN ENFERMEDADES RARAS - NEUROFISER">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - NEUROBIOLOGÍA Y FISIOPATOLOGÍA MOLECULAR EN ENFERMEDADES RARAS - NEUROFISER"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - PSIQUIATRÍA Y ENFERMEDADES NEURODEGENERATIVAS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - PSIQUIATRÍA Y ENFERMEDADES NEURODEGENERATIVAS"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - QUÍMICA MÉDICA PARA EL DESARROLLO DE FÁRMACOS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - QUÍMICA MÉDICA PARA EL DESARROLLO DE FÁRMACOS"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - RECEPTORES NUCLEARES EN PATOLOGÍA CARDIOMETABÓLICAS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - RECEPTORES NUCLEARES EN PATOLOGÍA CARDIOMETABÓLICAS"
                : "x"}
            </option>
            <option value="ÁREA METABOLISMO Y DAÑO ORGÁNICO - GENÉTICA DE LA OSTEOPOROSIS">
              {userLang === "es"
                ? "ÁREA METABOLISMO Y DAÑO ORGÁNICO - GENÉTICA DE LA OSTEOPOROSIS"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - HISTOPATOLOGÍA E INGENIERÍA TISULAR">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - HISTOPATOLOGÍA E INGENIERÍA TISULAR"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - TUMORES DEL SISTEMA NERVIOSO CENTRAL">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - TUMORES DEL SISTEMA NERVIOSO CENTRAL"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - CÁNCER COLORRECTAL Y NUEVOS DESARROLLOS TERAPÉUTICOS EN TUMORES SÓLIDOS">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - CÁNCER COLORRECTAL Y NUEVOS DESARROLLOS TERAPÉUTICOS EN TUMORES SÓLIDOS"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - BIOLOGÍA EN CÁNCER DE MAMA">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - BIOLOGÍA EN CÁNCER DE MAMA"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - CÁNCER CUTÁNEO">
              {userLang === "es" ? "ÁREA ONCOLÓGICA - CÁNCER CUTÁNEO" : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - IMAGEN MOLECULAR Y METABOLOMÍA">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - IMAGEN MOLECULAR Y METABOLOMÍA"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - MICROBIOLOGÍA MOLECULAR Y PATOGÉNESIS MICROBIANA">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - MICROBIOLOGÍA MOLECULAR Y PATOGÉNESIS MICROBIANA"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - NEOPLASIAS DE LÍNEA MIELOIDE">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - NEOPLASIAS DE LÍNEA MIELOIDE"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - SÍNDROMES LINFOPROLIFERATIVOS">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - SÍNDROMES LINFOPROLIFERATIVOS"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - TRASPLANTE HEMATOPOYÉTICO">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - TRASPLANTE HEMATOPOYÉTICO"
                : "x"}
            </option>
            <option value="ÁREA ONCOLÓGICA - INVESTIGACIÓN TRASLACIONAL DE TUMORES SÓLIDOS PEDIÁTRICOS">
              {userLang === "es"
                ? "ÁREA ONCOLÓGICA - INVESTIGACIÓN TRASLACIONAL DE TUMORES SÓLIDOS PEDIÁTRICOS"
                : "x"}
            </option>
            <option value="ÁREA DE MEDICINA REPRODUCTIVA - COMUNICACIÓN MATERNO-FETAL">
              {userLang === "es"
                ? "ÁREA DE MEDICINA REPRODUCTIVA - COMUNICACIÓN MATERNO-FETAL"
                : "x"}
            </option>
            <option value="ÁREA DE MEDICINA REPRODUCTIVA - MEDICINA REPRODUCTIVA">
              {userLang === "es"
                ? "ÁREA DE MEDICINA REPRODUCTIVA - MEDICINA REPRODUCTIVA"
                : "x"}
            </option>
            <option value="ÁREA DE MEDICINA REPRODUCTIVA - SALUD DE LA MUJER">
              {userLang === "es"
                ? "ÁREA DE MEDICINA REPRODUCTIVA - SALUD DE LA MUJER"
                : "x"}
            </option>
            <option value="ÁREA DE MEDICINA REPRODUCTIVA - TERAPIAS FRENTE A LA ENDOMETRIOSIS Y EL CÁNCER ENDOMETRIAL">
              {userLang === "es"
                ? "ÁREA DE MEDICINA REPRODUCTIVA - TERAPIAS FRENTE A LA ENDOMETRIOSIS Y EL CÁNCER ENDOMETRIAL"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - FARMACIA">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - FARMACIA"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - MEDICINA DIGESTIVA">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - MEDICINA DIGESTIVA"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - NEUROLOGÍA">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - NEUROLOGÍA"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - TRAUMATOLOGÍA Y ORTOPEDIA">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - TRAUMATOLOGÍA Y ORTOPEDIA"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - UROLOGÍA">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - UROLOGÍA"
                : "x"}
            </option>
            <option value="ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - CUIDADOS (INVESTENF-INCLIVA)">
              {userLang === "es"
                ? "ÁREA DE GRUPOS CLÍNICOS ASOCIADOS - CUIDADOS (INVESTENF-INCLIVA)"
                : "x"}
            </option>
          </select>
          <select
            value={userProgramasTransversalesOp1}
            onChange={getUserProgramasTransversalesOp1}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 1"
                : "Select Transversal Program Op. 1"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 1"
                : "Select Transversal Program Op. 1"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp2}
            onChange={getUserProgramasTransversalesOp2}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 2"
                : "Select Transversal Program Op. 2"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 2"
                : "Select Transversal Program Op. 2"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp3}
            onChange={getUserProgramasTransversalesOp3}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 3"
                : "Select Transversal Program Op. 3"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 3"
                : "Select Transversal Program Op. 3"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp4}
            onChange={getUserProgramasTransversalesOp4}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 4"
                : "Select Transversal Program Op. 4"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 4"
                : "Select Transversal Program Op. 4"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp5}
            onChange={getUserProgramasTransversalesOp5}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 5"
                : "Select Transversal Program Op. 5"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 5"
                : "Select Transversal Program Op. 5"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp6}
            onChange={getUserProgramasTransversalesOp6}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 6"
                : "Select Transversal Program Op. 6"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 6"
                : "Select Transversal Program Op. 6"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>

          <select
            value={userProgramasTransversalesOp7}
            onChange={getUserProgramasTransversalesOp7}
            className="modal-select"
            title={
              userLang === "es"
                ? "Seleccione Programa Transversal Op. 7"
                : "Select Transversal Program Op. 7"
            }
          >
            <option disabled value="-">
              {userLang === "es"
                ? "Seleccione Programa Transversal Op. 7"
                : "Select Transversal Program Op. 7"}
            </option>
            <option value="NINGUNO">NINGUNO</option>
            <option value="PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL">
              PT SOBREPESO, OBESIDAD Y RIESGO METABÓLICO, VASCULAR Y RENAL
            </option>
            <option value="PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR">
              PT DETECCIÓN Y CONTROL DE LA DISFUNCIÓN VENTRICULAR
            </option>
            <option value="PT ENFERMEDADES RARAS">PT ENFERMEDADES RARAS</option>
            <option value="PT DETERIORO NEUROLÓGICO">
              PT DETERIORO NEUROLÓGICO
            </option>
            <option value="PT ONCOLOGÍA TRASLACIONAL">
              PT ONCOLOGÍA TRASLACIONAL
            </option>
            <option value="PT MEDICINA REPRODUCTIVA">
              PT MEDICINA REPRODUCTIVA
            </option>
            <option value="PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS">
              PT ENVEJECIMIENTO Y ENFERMEDADES ASOCIADAS
            </option>
          </select>
          <p style={{textAlign:"left", fontSize:"12px", width:"90%"}}>*Campos obligatorios</p>
          <div className="modal-select-btns-div">
            {!isLoading && (
              <div className="modal-profile-buttons-div">
                <button
                  disabled={
                    !userGender ||
                    userGender === "-" ||
                    !userAge ||
                    userAge === "-" ||
                    !userDepartament ||
                    userDepartament === "-" ||
                    !userBusiness ||
                    userBusiness === "-"
                  }
                  style={{
                    cursor:
                      !userGender ||
                      userGender === "-" ||
                      !userAge ||
                      userAge === "-" ||
                      !userDepartament ||
                      userDepartament === "-" ||
                      !userBusiness ||
                      userBusiness === "-"
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => {
                    updateUserProfile();
                    setShowModalDepartament(false);
                  }}
                  className="btn-primary-m btn-options-quiz"
                >
                  {userLang === "en" ? "SAVE" : "GUARDAR"}
                </button>
                <button
                  onClick={() => setShowModalDepartament(false)}
            
                  className="btn-accent btn-options-quiz"
                >
                  {userLang === "en" ? "CANCEL" : "CANCELAR"}
                </button>
              </div>
            )}
            {isLoading && (
              <div className="modal-profile-buttons-div">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfile;
